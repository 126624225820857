import React from "react";
import dayjs from "dayjs";
import styles from "./DesktopProposedTimes.module.css";
import { ThreeDots } from "react-loader-spinner";
import { Alert } from "antd";

interface Time {
  datetime: string;
  is_primary: boolean;
}

interface ProposedTimeProps {
  datetime: string;
  is_primary: boolean;
  onMarkPrimary: (datetime: string) => void;
  onRemoveTime: (datetime: string) => void;
}

const ProposedTime: React.FC<ProposedTimeProps> = ({
  datetime,
  is_primary,
  onMarkPrimary,
  onRemoveTime,
}) => {
  return (
    <div key={datetime} className={styles.proposed_time_container}>
      <div className={styles.proposed_time_content}>
        <span>{dayjs(datetime).format("h:mmA dddd, MMMM DD")}</span>
        {!is_primary && (
          <p
            onClick={() => onMarkPrimary(datetime)}
            className={styles.preferred_time_text}
          >
            Click to set as preferred
          </p>
        )}
      </div>
      <div
        onClick={() => onRemoveTime(datetime)}
        className={styles.remove_time_button}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
        >
          <path
            d="M1.37106 0.5L0 1.87106L5.15445 7.02551L0.0255815 12.1544L1.34571 13.4745L6.47458 8.34564L11.6289 13.5L13 12.1289L7.84564 6.97458L12.9747 1.84557L11.6545 0.525437L6.52551 5.65445L1.37106 0.5Z"
            fill="#66686A"
          />
        </svg>
      </div>
    </div>
  );
};

interface ProposedTimesProps {
  times: Time[];
  is_submitting: boolean;
  error_message?: string;
  onMarkPrimary: (datetime: string) => void;
  onRemoveTime: (datetime: string) => void;
  onRequestSession: () => void;
}

const NO_SLOTS_REQUIRED = 4;

export const ProposedTimes: React.FC<ProposedTimesProps> = ({
  times,
  is_submitting,
  error_message,
  onMarkPrimary,
  onRemoveTime,
  onRequestSession,
}) => {
  const primary_timeslot = times.find((time) => time.is_primary);

  const alternative_times = times.filter((time) => !time.is_primary);

  return (
    <div className={styles.proposed_times}>
      <h2>Booking Details</h2>
      <h3>Preferred date and time</h3>
      {primary_timeslot?.datetime ? (
        <ProposedTime
          is_primary={primary_timeslot?.is_primary}
          datetime={primary_timeslot?.datetime}
          onMarkPrimary={onMarkPrimary}
          onRemoveTime={onRemoveTime}
        />
      ) : (
        <p>No primary time selected</p>
      )}
      <h3>
        Alternative dates and times{" "}
        {alternative_times.length > 0 && `(${alternative_times.length})`}
      </h3>
      <div className={styles.times_container}>
        {alternative_times.length < NO_SLOTS_REQUIRED && (
          <p>
            Please select at least{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              three more times
            </span>{" "}
            that you're available to meet with your mentor.{" "}
          </p>
        )}
        {alternative_times.map((time) => (
          <ProposedTime
            is_primary={time?.is_primary}
            datetime={time?.datetime}
            onMarkPrimary={onMarkPrimary}
            onRemoveTime={onRemoveTime}
          />
        ))}
      </div>
      <button
        onClick={onRequestSession}
        disabled={times.length < NO_SLOTS_REQUIRED}
        className={styles.submit_button}
      >
        {is_submitting ? (
          <div style={{ marginTop: "-2px" }}>
            <ThreeDots
              wrapperStyle={{ margin: "auto", display: "block" }}
              color="#FFF"
              height={24}
              width={32}
            />
          </div>
        ) : (
          "Send Availability"
        )}
      </button>
      {!!error_message && <Alert type="error" message={error_message} />}
      {/* <span className={styles.rescheduling_notes}>
        <p>Add rescheduling notes</p>
      </span>
      {show_rescheduling_notes && (
        <textarea
          style={{ marginTop: "4px", padding: "12px" }}
          className="black"
          value={rescheduling_notes}
          onChange={(e) => onUpdateReschedulingNotes(String(e.target.value))}
          id="description"
          data-testid="description"
          placeholder={`Please list the talking points and questions that you'd like to discuss.`}
          autoFocus
          rows={5}
        />
      )} */}
    </div>
  );
};

export default ProposedTimes;
