import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Reschedule.module.css";
import VerticalNavigation, {
  RouteSchema,
} from "./components/BookingVerticalNavigation/BookingVerticalNavigation";
import Header from "components/Organisms/Header";
import ProfileContent from "./components/ProfileContent";
import BookingCalendarComponent from "./components/ReschedullingCalendar/ReschedullingCalendar.component";
import SessionSelectorComponent from "./components/SessionSelector/SessionSelector.component";
import { useMemo, useState } from "react";
import DesktopProposedTimesComponent from "./components/ProposedTimes/Desktop/DesktopProposedTimes.component";
import MobileProposedTimesComponent from "./components/ProposedTimes/Mobile/MobileProposedTimes.component";
import { UseRescheduleSessionReturn } from "./useReschduleSession";
import { getAvailabilityOnGivenDate } from "./components/SessionSelector/SessionSelection.helpers";
import { PROFILE_TYPE } from "entities/Profile";

interface ResecheduleSessionComponentProps extends UseRescheduleSessionReturn {
  user_timezone: string;
}

const ResecheduleSessionComponent = ({
  user_role,
  user_timezone,
  availability,
  loading_session_data,
  profile,
  session,
  selected_slots,
  primary_slot,
  removePrimarySlot,
  setPrimarySlot,
  toggleSelectSlot,
  addAllSlotsForDate,
  requestReschedule,
  clearDate,
  reschedule_session_is_submitting,
}: ResecheduleSessionComponentProps) => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const [selected_date, setSelectedDate] = useState<string | undefined>(
    undefined
  );
  const [rescheduleSuccess, setRescheduleSuccess] = useState<boolean>(false);

  const selected_date_availability = useMemo(
    () =>
      (user_timezone &&
        selected_date &&
        getAvailabilityOnGivenDate(
          availability,
          selected_date,
          user_timezone
        )) ||
      [],
    [availability, selected_date, user_timezone]
  );

  const handleReschedule = () => {
    requestReschedule(() => {
      setRescheduleSuccess(true);
    });
  };

  const Titles = {
    mentor: "Profile",
    date: "Date",
    time: "Time",
  };

  const ROUTES: RouteSchema[] = [
    {
      id: "profile",
      title: Titles.mentor,
      url: "#profile",
    },
    {
      id: "booking-date",
      title: Titles.date,
      url: "#booking-date",
    },
    {
      id: "booking-time",
      title: Titles.time,
      url: "#booking-time",
    },
  ];

  if (rescheduleSuccess) {
    return (
      <div className={styles.successPage}>
        <h2>Success!</h2>
        <p>Your session reschedule request has been sent.</p>
        <button onClick={() => navigate("/")}>Return to Home</button>
      </div>
    );
  }

  return (
    <>
      <Header>
        <h3>
          Reschedule your session{" "}
          {!loading_session_data && `with ${profile?.given_name}`}
        </h3>
      </Header>
      <div className={styles.account_content_container}>
        <VerticalNavigation routes={ROUTES} selected_tab={hash} />
        <div className={styles.booking_container}>
          <div
            className="component-tab"
            style={{ marginTop: "0px", padding: "16px", paddingTop: "8px" }}
          >
            <h4 style={{ marginTop: "8px", marginBottom: "4px" }}>
              Select multiple times to reschedule with {profile?.given_name}
            </h4>
            <p>
              Select a few dates and times that work best for you, and we’ll
              share them with your{" "}
              {profile?.type == PROFILE_TYPE.MENTEE ? "mentee " : "mentor "}.
              This way, they can choose a time that fits both schedules, making
              rescheduling easy and flexible. Let’s get you back on track!
            </p>
          </div>
          <div className="component-tab" key={ROUTES[0].id} id="mentor">
            <ProfileContent
              profile={profile}
              is_loading={loading_session_data}
            />
          </div>
          <div className="component-tab" key={ROUTES[1].id} id="booking-date">
            <BookingCalendarComponent
              availability={availability}
              user_timezone={user_timezone}
              selected_iso_date={selected_date}
              is_loading={loading_session_data}
              onSelectCalendarDay={(date: string) => setSelectedDate(date)}
            />
          </div>
          <div className="component-tab" key={ROUTES[2].id} id="booking-time">
            {selected_date && !loading_session_data && (
              <SessionSelectorComponent
                ideal_proposed_time={primary_slot}
                selected_date={selected_date}
                proposed_datetimes={selected_slots}
                duration={session?.duration || 0}
                availability={selected_date_availability}
                onClickTimeslot={toggleSelectSlot}
                selectAllTimeSlot={() => addAllSlotsForDate(selected_date)}
                clearAllTimeSlot={() => clearDate(selected_date)}
              />
            )}
          </div>
        </div>
        <>
          {window.innerWidth > 900 ? (
            <DesktopProposedTimesComponent
              times={
                selected_slots
                  ? selected_slots.map((slot) => ({
                      datetime: slot,
                      is_primary: slot == primary_slot,
                    }))
                  : []
              }
              is_submitting={reschedule_session_is_submitting}
              //   error_message={error_message}
              onMarkPrimary={setPrimarySlot}
              onRemoveTime={toggleSelectSlot}
              onRequestSession={handleReschedule}
            />
          ) : (
            <MobileProposedTimesComponent
              times={
                selected_slots
                  ? selected_slots.map((slot) => ({
                      datetime: slot,
                      is_primary: slot == primary_slot,
                    }))
                  : []
              }
              is_submitting={reschedule_session_is_submitting}
              //   error_message={error_message}
              onMarkPrimary={setPrimarySlot}
              onRemoveTime={toggleSelectSlot}
              onRequestSession={handleReschedule}
            />
          )}
        </>
      </div>
    </>
  );
};

export default ResecheduleSessionComponent;
